<template>
  <div>
    <b-modal v-model="showActionModal" @ok="userAction(action, false, true)">
      {{
        `Are you sure to ${action} user with id ${resetUserId} ${
          action === "reset" ? "password" : ""
        } ?`
      }}
    </b-modal>

    <b-modal v-model="showAddUserModal" centered @ok="addUser">
      <b-row>
        <h1 class="d-flex justify-content-center mb-2">Add User</h1>
        <b-col md="6" xl="4" class="mb-1">
          <!-- basic -->
          <b-form-group label="User Name" label-for="basicInput">
            <b-form-input
              id="basicInput"
              v-model="addUserData.userName"
              placeholder="Enter user name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="4" class="mb-1">
          <!-- basic -->
          <b-form-group label="Email" label-for="basicInput">
            <b-form-input
              id="basicInput"
              v-model="addUserData.email"
              placeholder="Enter email"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="4" class="mb-1">
          <!-- basic -->
          <b-form-group label="Mobile" label-for="basicInput">
            <b-form-input
              id="basicInput"
              v-model="addUserData.mobile"
              placeholder="Enter mobile"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <div class="buttonsWrapper">
      <b-button variant="primary" class="mb-2" @click="showAddUserPopup">
        Add User
      </b-button>
      <div class="custom-search">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      v-if="rows && rows.length"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :theme="tableTheme"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'fullName'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="userAction('reset', props.formattedRow.id, false)"
              >
                <feather-icon icon="KeyIcon" class="mr-50" />
                <span>Reset Password</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="userAction('delete', props.formattedRow.id, false)"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
// eslint-disable-next-line import/no-extraneous-dependencies
import store from "@/store/index";
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapActions, mapState } from "vuex";
import "vue-good-table/dist/vue-good-table.css";

export default {
  components: {
    VueGoodTable,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      isOpen: false,
      showActionModal: false,
      showAddUserModal: false,
      action: null,
      resetUserId: null,
      addUserData: {},
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "User Name",
          field: "userName",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Mobile",
          field: "mobile",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapState("userManagement", ["users"]),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    tableTheme() {
      if (store.state.appConfig.layout.skin === "dark") {
        return "nocturnal";
      }
      return "";
    },
    rows() {
      return this.users;
    },
  },
  created() {
    this.getUsers();
    this.rows = this.users;
  },
  methods: {
    ...mapActions("userManagement", [
      "getUsers",
      "registerUser",
      "resetUserPassword",
      "deleteUser",
    ]),
    showPopup() {
      this.showActionModal = true;
    },
    showAddUserPopup() {
      this.showAddUserModal = true;
    },
    addUser() {
      this.registerUser({
        userName: this.addUserData.userName,
        email: this.addUserData.email,
        mobile: this.addUserData.mobile,
      });
      this.rows.push({
        userName: this.addUserData.userName,
        email: this.addUserData.email,
        mobile: this.addUserData.mobile,
      });
      this.addUserData = {};
    },
    userAction(action, id, ok) {
      switch (action) {
        case "reset":
          if (id) {
            this.resetUserId = id;
            this.action = "reset";
          }
          if (ok) {
            this.resetUserPassword({
              id: this.resetUserId,
            });
            this.resetUserId = null;
            this.action = null;
          } else {
            this.showPopup();
          }
          break;
        case "delete":
          if (id) {
            this.action = "delete";
            this.resetUserId = id;
          }
          if (ok) {
            this.deleteUser({
              id: this.resetUserId,
            });
            this.users.splice(
              this.users.findIndex((i) => i.id === this.resetUserId),
              1
            );
            // this.adminAct = null;
          } else {
            this.showPopup();
          }
          break;
        default:
        // code block
      }
    },
  },
};
</script>
<style lang="scss">
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}
.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}
.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}
</style>
